<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="newsPageBox">
    <!-- 背景图片 -->
    <div class="newsImageBox">
      <img class="newsImage" src="@/assets/img/newsImage.png" alt="bg">
      <div class="characters">
        <span>平台快报</span>
        <img src="@/assets/img/newsSmallImg.png" alt="图片">
      </div>
    </div>
    <!-- tab栏 -->
    <div class="tabBox">
      <div class="tab">
        <span
          v-for="(item, index) in newsType"
          :key="item.val"
          :class="[activeIndex === index ? 'active' : '']"
          @click="getActive(item, index)"
        >{{ item.name }}</span>
      </div>
    </div>
    <!-- 新闻列表 -->
    <div class="newsList">
      <router-link
        v-for="item in listData"
        :key="item.id"
        :to="{ path: '/news/newsDetail', query: { id: item.id } }"
        class="news"
      >
        <div class="newsTitle">
          <h4 class="elp-1">
            <span v-if="item.isTop===1">置顶</span>{{ item.title }}
          </h4>
          <span>{{ item.createTime }}</span>
        </div>
        <p class="newsCotent elp-2" v-html="item.content" />
      </router-link>
      <Pagination
        v-if="listData.length > 0"
        :get-data-list="getdata"
        :params="formInline"
        :total="total"
      />
      <NoData v-else :type="3" />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/NoData'
import Pagination from '@/components/Pagination.vue'
import { getNewsListByPage } from '@/http/common'
export default {
  components: { Pagination, NoData },
  data() {
    return {
      formInline: {
        pageNum: 1,
        pageSize: 10,
        newsPlat: 3,
        newsType: '' // 文章类型：1-公司新闻，2-行业动态
      },
      listData: [],
      total: 0,
      activeIndex: 0,
      newsType: [
        { name: '全部新闻', val: '' },
        { name: '公司新闻', val: 1 },
        { name: '行业动态', val: 2 }
      ]
    }
  },
  mounted() {
    this.getdata()
  },
  methods: {
    getActive(item, index) {
      this.activeIndex = index
      this.formInline.newsType = item.val
      this.getdata()
    },
    getdata() {
      getNewsListByPage(this.formInline, (res) => {
        this.listData = [...res.data.pageData]
        // 把新闻内容里面的图片干掉
        this.listData.forEach(item => {
          item.content = item.content.replace(/<img.*?src=[\"|\']?(.*?)[\"|\']*?>/g, '')
        })
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss">
.newsPageBox {
  background: rgba(249, 249, 249, 1);
  padding-bottom: 24px;
  .newsList {
    margin: 16px auto;
    width: $centerPlateWidth;
    background-color: #fff;
    min-height: calc(100vh - 470px);
    .news {
      display: block;
      margin: 0 24px;
      padding: 32px 0;
      border-bottom: 1px solid #e7e7e7;
      .newsCotent {
        margin-top: 10px;
        font-size: 14px;
        color: #666;
      }
      .newsTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        > span {
          flex: 0 0 176px;
          text-align: right;
          color: #999999;
          font-size: 14px;
        }
        h4 span {
          display: inline-block;
          width: 44px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: #d31e0e;
          background-color: rgba(211, 30, 14, 0.08);
          margin-right: 8px;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .tabBox {
    width: 100%;
    height: 46px;
    background-color: #fff;
    .tab {
      display: flex;
      margin: auto;
      width: $centerPlateWidth;
      height: 100%;
      span {
        display: flex;
        align-items: center;
        height: 100%;
        color: #666;
        font-size: 16px;
        box-sizing: border-box;
        border-bottom: 2px solid #fff;
        margin-right: 56px;
        cursor: pointer;
      }
      .active {
        color: $themeColors;
        border-color: $themeColors;
      }
    }
  }
  .newsImageBox {
    position: relative;
    width: 100%;
    .characters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: $centerPlateWidth;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      font-size: 48px;
      img {
        height: 48px;
        width: 428px;
        display: block;
      }
    }
    .newsImage {
      width: 100%;
      display: block;
    }
  }
}
</style>
